import React from 'react'
import { Card, CardContent, CardHeader, IconButton, Typography } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import DeleteIcon from '@mui/icons-material/Delete'
import LinesEllipsis from 'react-lines-ellipsis'
import { DateTime } from 'luxon'

const DocumentsCards = ({ data, isSupportPerson, showFile, downloadFile, handleDialogOpen }) => {
  return (
    <div>
      {
        data && data.map((entry) => (
          <Card
            sx={{
              padding: 1,
              margin: 2,
            }}
            key={entry.id}
          >
            <CardHeader title={entry.name}/>
            <CardContent>
              <LinesEllipsis
                text={entry.url ? entry.url : ''}
                maxLine="1"
                ellipsis="..."
                trimRight
                basedOn="letters"/>
              <Typography>
                {entry.creationDate ?
                  DateTime.fromISO(entry.creationDate)
                    .toFormat('dd.MM.yyyy - HH:mm')
                  : '--.--.---- --:--'
                }
              </Typography>
            </CardContent>
            {isSupportPerson && (
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
              }}>
                <IconButton size={'small'} onClick={() => showFile(entry.id)} sx={{ paddingRight: 1 }}>
                  <VisibilityIcon/>
                </IconButton>
                <IconButton size={'small'} onClick={() => downloadFile(entry.id, entry.url)}>
                  <CloudDownloadIcon/>
                </IconButton>
                {isSupportPerson && (
                  <IconButton size={'small'} onClick={() => handleDialogOpen({ id: entry.id, name: entry.url })}>
                    <DeleteIcon/>
                  </IconButton>
                )}
              </div>
            )}
          </Card>
        ))
      }
    </div>
  )
}

DocumentsCards.propTypes = {}

export default DocumentsCards
