import Fab from '@mui/material/Fab'
import { NavLink as RouterLink } from 'react-router-dom'
import { Tooltip } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'

const IconBox = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  transform: theme.skewText,
}))

const NavigateFab = ({ path, text, children }) => {
  return (

    <Tooltip title={text} arrow>
      <Fab
        color={'primary'}
        component={RouterLink}
        to={path}
        sx={{
          position: 'fixed',
          bottom: 40,
          right: 40,
        }}
        id={'navigateFab'}
      >
        <IconBox>
          {children}
        </IconBox>
      </Fab>
    </Tooltip>
  )
}

NavigateFab.propTypes = {
  path: PropTypes.string,
  text: PropTypes.string,
}

export default NavigateFab