import React from 'react'
import { getRoles } from '../../../../security/roles'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@mui/material'
import { ChargingPointAccordion } from '../../../../components'

const CpTable = (props) => {
  const { chargingPoints, onEditExtender, disabled, onActivate, pvSettings, hasPv, version, selectMore } = props
  const userRoles = useSelector(state => getRoles(state))
  return (
    <div>
      <Grid container>
        <Grid item xs={3}>
          <Typography variant={'h6'}>
            Ladepunkt
          </Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography variant={'h6'}>
            {version >= 2 &&
              ('Hersteller')
            }
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant={'h6'}>
            {version >= 2 ?
              ('Serien-Nr.')
              :
              ('Typ')
            }
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant={'h6'}>
            {version >= 2 ?
              ('Firmware')
              :
              ('Ladeleistung')
            }
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant={'h6'}>
            Zone
          </Typography>
        </Grid>
        <Grid item xs={0.5}>
          <Typography variant={'h6'}>
            {hasPv &&
              ('PV')
            }
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant={'h6'} align={'right'}>
            Aktivierung
          </Typography>
        </Grid>
      </Grid>
      {chargingPoints && chargingPoints.length > 0 && chargingPoints
        .filter(cp => isCpLMS01(cp) || isCpLMS02Main(cp))
        .map((item, index) => {
          return (
            <ChargingPointAccordion key={item.id}
                                    item={item}
                                    disabled={disabled}
                                    onActivate={onActivate}
                                    onEditExtender={onEditExtender}
                                    pvSettings={pvSettings}
                                    hasPv={hasPv}
                                    userRoles={userRoles}
                                    version={version}
                                    selectMore={selectMore}/>
          )
        })}
    </div>
  )
}

function isCpLMS01 (chargingPoint) {
  return chargingPoint.interfaceType === 'na' || chargingPoint.interfaceType == null
}

function isCpLMS02Main (chargingPoint) {
  return chargingPoint.connectorId == null || chargingPoint.connectorId === 1
}

CpTable.propTypes = {
  className: PropTypes.string,
  chargingPoints: PropTypes.array,
  onEditExtender: PropTypes.func,
  userRoles: PropTypes.array,
  disabled: PropTypes.bool,
  onActivate: PropTypes.func,
}

export default CpTable