import { Tooltip, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import { useSnackbar } from 'notistack'
import { useSelector } from 'react-redux'
import { LoadingBackdrop, LoadMoreButton } from '../../../components'
import DialogDelete from '../../../components/ConfirmDialog/ConfirmDialog'
import { getRoles, isLMSOwner, isServicePerson } from '../../../security/roles'
import { backendApiClient } from '../../../apiClient'
import Grid from '@mui/material/Grid'
import TagsTable from '../Tags/TagsTable'
import TagsList from '../Tags/TagsList'
import BlockIcon from '@mui/icons-material/Block'
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from '../../Configuration/Chargepoints/lmsVersionTwo/ChargingPointVersionTwoConstants'
import useMediaQuery from '@mui/material/useMediaQuery'
import Page from '../../../components/Page/Page'
import { styled } from '@mui/material/styles'
import ChargingPointsCardsHelpText from '../../../help-texts/FL_Authorization_ChargingPoints_Cards.md'

const ContentDiv = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(4),
}))

const ButtonDiv = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  marginTop: '18px',
})

const CustomDeleteIcon = () => (
  <Tooltip title={'Karte entfernen'}>
    <BlockIcon/>
  </Tooltip>
)

const ChargingPointTagsPage = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const { clientId, pointId } = useParams()
  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar()
  const userRoles = useSelector(state => getRoles(state))
  const [loading, setLoading] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [itemToDeleteCPFrom, setItemToDeleteCPFrom] = useState()
  const [data, setData] = useState({})
  const [content, setContent] = useState([])
  const [name, setName] = useState(location.state?.name)
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const [helpText, setHelpText] = useState()

  const CP_ID_TOKENS_URL = `/api/client/${clientId}/chargingpoints/{chargingPoint}/id-tokens`
  const ID_TOKEN_URL = `/api/client/${clientId}/id-tokens/{token-id}`
  const CP_URL = `/api/client/${clientId}/configuration/chargingpoints/{chargingPoint}`

  const CP_Cards_TITLE = 'Ladekarten'
  const TITLE = `Aktive Ladekarten für ${name}`

  const fetchData = () => {
    if (!name) {
      fetchChargingPoint()
    }
    fetchTokensForCp()
  }

  const fetchChargingPoint = () => {
    const pointUrl = CP_URL
      .replace(/{chargingPoint}/g, pointId)
    backendApiClient.get(pointUrl)
      .then((response) => {
        setName(response.name)
      })
      .catch((error) => {
        throw new Error(error)
      })
  }

  const fetchTokensForCp = (cursor) => {
    const idTokensUrl = CP_ID_TOKENS_URL
      .replace(/{chargingPoint}/g, pointId)
    let queryParams = cursor ? { cursor: cursor } : {}
    backendApiClient.get(idTokensUrl, queryParams)
      .then((response) => {
        setData(response)
        if (cursor) {
          setContent(content.concat(response.content))
        } else {
          setContent(response.content)
        }
      })
      .catch((error) => {
        throw new Error(error)
      })
  }

  const handleDelete = () => {
    setDeleteOpen(false)
    setLoading(true)
    const validForChargingPoints = itemToDeleteCPFrom.validForChargingPoints.filter(function (item) {
      return item !== parseInt(pointId)
    })
    const updatedTag = { ...itemToDeleteCPFrom, validForChargingPoints: validForChargingPoints }
    updateTag(updatedTag)
      .finally(() => setLoading(false))
  }

  const updateTag = (data) => {
    const url = ID_TOKEN_URL
      .replace(/{token-id}/g, itemToDeleteCPFrom.value)
    return backendApiClient.put(url, data)
      .then(() => {
        fetchTokensForCp()
        enqueueSnackbar(SNACKBAR_SUCCESS, {
          variant: 'success',
        })
      })
      .catch(() => {
        enqueueSnackbar(SNACKBAR_ERROR, {
          variant: 'error',
        })
      })
  }

  const handleDialogOpen = (entry) => {
    try {
      setItemToDeleteCPFrom(entry)
      setDeleteOpen(true)
    } catch (e) {
      throw new Error(e)
    }
  }

  const handleDialogClose = () => {
    try {
      setDeleteOpen(false)
    } catch (e) {
      throw new Error(e)
    }
  }

  const fetchHelpText = () => {
    fetch(ChargingPointsCardsHelpText)
      .then(res => res.text())
      .then(text => setHelpText(text))
      .catch(error => throw new Error(error))
  }

  useEffect(() => {
    fetchData()
    fetchHelpText()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleEdit (tag) {
    navigate(`../../tags/${tag.value}/edit`, { state: { tag: tag } })
  }

  return (
    <Page viewName={CP_Cards_TITLE} pageTitle={CP_Cards_TITLE} onBack={() => navigate(-1)}
          helpText={helpText} helpTitle={CP_Cards_TITLE}>
      <LoadingBackdrop open={loading}/>
      <DialogDelete
        open={deleteOpen}
        handleClose={handleDialogClose}
        handleConfirm={handleDelete}
        dialogText={'Ladekarte für diesen Ladepunkt entfernen?'}
      />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          item
          xs={12}
        >
          <Typography variant={'h4'}>
            {TITLE}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <ContentDiv>
            {smallScreen ?
              <TagsList entry={content} handleEdit={handleEdit} handleDelete={handleDialogOpen}
                        allowEdit={isServicePerson(userRoles) || isLMSOwner(userRoles)}
                        CustomDeleteIcon={CustomDeleteIcon}/>
              :
              <TagsTable content={content} handleEdit={handleEdit} handleDelete={handleDialogOpen}
                         allowEdit={isServicePerson(userRoles) || isLMSOwner(userRoles)}
                         CustomDeleteIcon={CustomDeleteIcon}/>
            }
          </ContentDiv>
        </Grid>
      </Grid>
      <ButtonDiv>
        {data?.last ? <></> :
          <LoadMoreButton
            onClick={() => {
              fetchTokensForCp(data?.pageable?.cursor)
            }}
            label={'Weitere Karten anzeigen'}
          />
        }
      </ButtonDiv>

    </Page>
  )
}

export default ChargingPointTagsPage