import React from 'react'
import PropTypes from 'prop-types'
import { hasRequiredRole } from '../../security/roles'
import { Navigate } from 'react-router-dom'
import { logoutUser } from '../../store'

// eslint-disable-next-line react/prefer-stateless-function
const AuthGuard = (props) => {

  const { children, requiredRole, userRoles, newPasswordRequired } = props

  if (userRoles && userRoles.includes('FL_cp_user') && userRoles.length === 1) {
    logoutUser()
  }
  if (requiredRole === undefined) {
    return (
      <>
        {children}
      </>
    )
  }
  if (hasRequiredRole(requiredRole, userRoles)) {
    return (
      <>
        {children}
      </>
    )
  }

  return (
    <Navigate to={'/'}/>
  )

}

AuthGuard.propTypes = {
  children: PropTypes.any,
  requiredRole: PropTypes.any,
  userRoles: PropTypes.any,
}

export default AuthGuard
