import React from 'react'

const CircularProgressBar = ({ strokeWidth, size, progress, emptyColor, startColor, endColor, middleColor }) => {

  const diameterSize = 50
  const width = diameterSize + strokeWidth
  const firstHalfProg = progress > diameterSize ? 1 : progress / diameterSize
  const secondHalfProg = progress <= diameterSize ? 0 : (progress - diameterSize) / diameterSize
  const halfCircumference = ((Math.PI * 2) * (diameterSize / 2) / 2)

  const firstHalfGradientId = 'firstHalfGradientId'
  const secondHalfGradientId = 'secondHalfGradientId'

  return (
    <div style={{ width: `${size}px`, height: `${size}px` }}>
      <svg viewBox={`0 0 ${width} ${width}`}>
        <defs>
          <linearGradient id={firstHalfGradientId} x1="50%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor={startColor}/>
            <stop offset="90%" stopColor={middleColor}/>
          </linearGradient>

          <linearGradient id={secondHalfGradientId} x1="0%" y1="0%" x2="50%" y2="100%">
            <stop offset="0%" stopColor={endColor}/>
            <stop offset="90%" stopColor={middleColor}/>
          </linearGradient>
        </defs>

        <path
          fill="none"
          stroke={emptyColor}
          d={`
              M ${strokeWidth / 2} ${width / 2}
              a ${diameterSize / 2} ${diameterSize / 2} 0 1 1 ${diameterSize} 0
              a ${diameterSize / 2} ${diameterSize / 2} 0 1 1 -${diameterSize} 0
            `}
          strokeWidth={strokeWidth}
        />

        <path
          fill="none"
          stroke={`url(#${firstHalfGradientId})`}
          strokeDasharray={`${firstHalfProg * halfCircumference},${halfCircumference}`}
          strokeLinecap="round"
          d={`
              M ${width / 2} ${strokeWidth / 2}
              a ${diameterSize / 2} ${diameterSize / 2} 0 1 1 0 ${diameterSize}
            `}
          strokeWidth={strokeWidth}
        />

        {progress >= 50 &&
          <path
            fill="none"
            stroke={`url(#${secondHalfGradientId})`}
            strokeDasharray={`${secondHalfProg * halfCircumference},${halfCircumference}`}
            strokeLinecap="round"
            d={`
              M ${width / 2} ${width - strokeWidth / 2}
              a ${diameterSize / 2} ${diameterSize / 2} 0 0 1 0 -${diameterSize}
            `}
            strokeWidth={strokeWidth}
          />
        }
      </svg>
    </div>
  )
}

export default CircularProgressBar