import React, { useEffect, useState } from 'react'
import { backendApiClient } from '../../apiClient'
import { LoadingBackdrop } from '../../components'
import PropTypes from 'prop-types'
import { useSnackbar } from 'notistack'
import DialogDelete from '../../components/ConfirmDialog/ConfirmDialog'
import DocumentsTable from './DocumentsTable'
import DocumentsCards from './DocumentsCards'
import { useParams } from 'react-router'
import { downloadBlob } from '../../utils'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

const DocumentsContainer = ({ isSupportPerson }) => {
  const { clientId } = useParams()
  const URL = '/api/client/' + clientId + '/documents/'
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [itemToDelete, setItemToDelete] = useState()
  const { enqueueSnackbar } = useSnackbar()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const fetchData = () => {
    backendApiClient.get(URL)
      .then((response) => {
        setData(response.content)
      })
      .catch(error => throw new Error(error))
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const downloadFile = (documentId, documentName) => {
    setLoading(true)
    backendApiClient.get(URL + documentId)
      .then((r) => {
        backendApiClient.fileGet(URL + documentId + '/download')
          .then((response) => {
            return new Blob([response.data], { type: r.contentType })
          })
          .then((blob) => {
            downloadBlob(blob, documentName)
            setLoading(false)
          })
          .catch(() => {
            enqueueSnackbar('Es gab ein Fehler beim Herunterladen, bitte versuchen Sie es erneut!', {
              variant: 'error',
            })
            setLoading(false)
          })
      })
      .catch(() => {
        enqueueSnackbar('Es gab ein Fehler beim Herunterladen, bitte versuchen Sie es erneut!', {
          variant: 'error',
        })
        setLoading(false)
      })
  }

  const showFile = (documentId) => {
    setLoading(true)
    backendApiClient.fileGet(URL + documentId + '/download')
      .then((response) => {
        showBlob(response.data)
      })
      .catch(() => {
        enqueueSnackbar('Es gab ein Fehler beim Herunterladen, bitte versuchen Sie es erneut!', {
          variant: 'error',
        })
        setLoading(false)
      })
  }

  const showBlob = (blob) => {
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url

    const pdfWindow = window.open()
    pdfWindow.location.href = url
    setLoading(false)
  }

  const deleteFile = () => {
    setDeleteOpen(false)
    setLoading(true)

    backendApiClient.delete(URL + itemToDelete.id)
      .then(() => {
        fetchData()
        setLoading(false)
      })
      .catch(() => {
        enqueueSnackbar('Es gab ein Fehler beim löschen, bitte versuchen sie es erneut!', {
          variant: 'error',
        })
        setLoading(false)
      })
  }

  const handleDialogOpen = (entry) => {
    try {
      setItemToDelete(entry)
      setDeleteOpen(true)
    } catch (e) {
      throw new Error(e)
    }
  }

  const handleDialogClose = () => {
    try {
      setDeleteOpen(false)
    } catch (e) {
      throw new Error(e)
    }
  }

  return (
    <div>
      <LoadingBackdrop open={loading}/>
      <DialogDelete
        open={deleteOpen}
        handleClose={handleDialogClose}
        handleConfirm={deleteFile}
        dialogText={'Soll diese Datei wirklich gelöscht werden?'}
      />
      {smallScreen ?
        <DocumentsCards
          data={data}
          isSupportPerson={isSupportPerson}
          showFile={showFile}
          downloadFile={downloadFile}
          handleDialogOpen={handleDialogOpen}
        />
        :
        <DocumentsTable
          data={data}
          isSupportPerson={isSupportPerson}
          showFile={showFile}
          downloadFile={downloadFile}
          handleDialogOpen={handleDialogOpen}
        />
      }
    </div>
  )
}

DocumentsContainer.propTypes = {
  isSupportPerson: PropTypes.bool,
}

export default DocumentsContainer