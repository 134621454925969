import React, { useEffect, useState } from 'react'
import { backendApiClient } from '../../../apiClient'
import { useNavigate, useParams } from 'react-router'
import { useSnackbar } from 'notistack'
import { useLocation } from 'react-router-dom'
import FirmwareWidget from './FirmwareWidget'
import FirmwareHelpTextEdit from '../../../help-texts/administration/FL_Firmware_Versions_Edit.md'
import Page from '../../../components/Page/Page'
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from '../../Configuration/Chargepoints/lmsVersionTwo/ChargingPointVersionTwoConstants'

const EditFirmwarePage = () => {
  const { manufacturer, version } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const location = useLocation()

  const FIRMWARE_URL = `/api/administration/charging-point-firmwares/${manufacturer}/${version}`

  const [firmware, setFirmware] = useState(location.state?.firmware || {})
  const [loading] = useState(false)
  const [helpTextFirmwareEdit, setHelpTextFirmwareEdit] = useState()

  const FIRMWARE_EDIT_CARD_TITLE = 'Firmware Version bearbeiten'

  useEffect(() => {
    fetchFirmware()
    fetchHelpText()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchHelpText = () => {
    fetch(FirmwareHelpTextEdit)
      .then(res => res.text())
      .then(text => setHelpTextFirmwareEdit(text))
      .catch(error => throw new Error(error))
  }

  const fetchFirmware = () => {
    if (firmware) {
      return
    }
    backendApiClient.get(FIRMWARE_URL)
      .then((response) => {
        setFirmware(response)
      })
      .catch((e) => throw new Error(e))
  }

  const save = (data, actions) => {
    return backendApiClient.put(FIRMWARE_URL, data)
      .then(() => {
        navigate(-1)
        enqueueSnackbar(SNACKBAR_SUCCESS, {
          variant: 'success',
        })
      })
      .catch(() => {
        enqueueSnackbar(SNACKBAR_ERROR, { variant: 'error' })
      })
  }

  return (
    <Page viewName={FIRMWARE_EDIT_CARD_TITLE} helpText={helpTextFirmwareEdit} helpTitle={FIRMWARE_EDIT_CARD_TITLE} pageTitle={FIRMWARE_EDIT_CARD_TITLE} onBack={() => navigate('..')}>
      <FirmwareWidget save={save} firmware={firmware} loading={loading}/>
    </Page>
  )
}

export default EditFirmwarePage
