import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@mui/material'
import { Lock, WarningRounded } from '@mui/icons-material'
import { styled } from '@mui/material/styles'

const CustomIcon = styled('img')({
  padding: '3px',
})

const ChargingPointIcon = ({ status, activated, width, connectionStatus, systemStatus }) => {
  try {
    if (activated === false) {
      return (
        <Tooltip title={'nicht eingerichtet'}>
          <CustomIcon
            width={width}
            alt="nicht eingerichtet"
            src="/assets/chargingstation_loading_icons/LS_gray.svg"/>
        </Tooltip>
      )
    } else if (connectionStatus !== 'ONLINE' || systemStatus === 'PAUSED') {
      return (
        <Tooltip title={'Unbekannt'}>
          <CustomIcon
            width={width}
            alt="unbekannt"
            src="/assets/chargingstation_loading_icons/LS_gray.svg"/>
        </Tooltip>
      )
    }
  } catch (e) {
    throw new Error(e)
  }

  try {
    switch (status) {
      case 'CHARGING_COMPLETED':
        return (
          <Tooltip title={'Ladung beendet'}>
            <CustomIcon
              width={width}
              alt="Ladung beendet"
              src="/assets/chargingstation_loading_icons/LS_dark_green.svg"/>
          </Tooltip>
        )
      case 'CHARGING':
      case 'CHARGING_STARTED_EV':
        return (
          <Tooltip title={'Wird geladen'}>
            <CustomIcon
              width={width}
              style={{
                padding: '1px',
              }}
              alt="Wird geladen"
              src="/assets/chargingstation_loading_icons/LS_loading.svg"/>
          </Tooltip>
        )
      case 'AVAILABLE':
        return (
          <Tooltip title={'Verfügbar'}>
            <CustomIcon
              width={width}
              alt="Verfügbar"
              src="/assets/chargingstation_loading_icons/LS_light_green.svg"/>
          </Tooltip>
        )
      case 'CHARGING_ALLOWED':
        return (
          <Tooltip title={'Ladefreigabe'}>
            <CustomIcon
              width={width}
              alt="Ladefreigabe"
              src="/assets/chargingstation_loading_icons/LS_yellow.svg"/>
          </Tooltip>
        )
      case 'CONNECTED':
      case 'CHARGING_SUSPENDED_EV':
        return (
          <Tooltip title={'Verbunden, wartet auf Ladefreigabe'}>
            <CustomIcon
              width={width}
              alt="Verbunden, wartet auf Ladefreigabe"
              src="/assets/chargingstation_loading_icons/LS_yellow.svg"/>
          </Tooltip>
        )
      case 'UNAUTHORIZED':
        return (
          <Tooltip title={'Gesperrt'}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '5px',
            }}>
              <CustomIcon
                width={width}
                style={{ marginBottom: '-23px' }}
                alt="Gesperrt"
                src="/assets/chargingstation_loading_icons/LS_yellow.svg"/>
              <Lock/>
            </div>
          </Tooltip>
        )
      case 'FAULTY_LOCKED':
        return (
          <Tooltip title={'Störung'}>
            <CustomIcon
              width={width}
              alt="Störung"
              src="/assets/chargingstation_loading_icons/LS_red.svg"/>
          </Tooltip>
        )
      case 'UNDEFINED':
        return (
          <Tooltip title={'nicht betriebsbereit'}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '5px',
            }}>
              <CustomIcon
                width={width}
                alt="nicht betriebsbereit"
                style={{
                  marginBottom: '-23px',
                }}
                src="/assets/chargingstation_loading_icons/LS_gray.svg"/>
              <WarningRounded/>
            </div>
          </Tooltip>
        )
      default:
        return (
          <Tooltip title={'Unbekannt'}>
            <CustomIcon
              width={width}
              alt="Unbekannt"
              src="/assets/chargingstation_loading_icons/LS_gray.svg"/>
          </Tooltip>
        )
    }
  } catch (e) {
    throw new Error(e)
  }
}

ChargingPointIcon.propTypes = {
  status: PropTypes.string,
}

export default ChargingPointIcon
