import React, { useEffect, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { FormControl, MenuItem, Select } from '@mui/material'
import TabPanel, { tabIdProps } from '../../../components/TabPanel/TabPanel'
import { Outlet, useLocation, useNavigate, useRouteLoaderData } from 'react-router'
import useMediaQuery from '@mui/material/useMediaQuery'
import { CustomTab, CustomTabs } from '../../../components/TabMenu/TabMenu'

const ConfigurationTabLayout = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const location = useLocation()

  const { customer } = useRouteLoaderData('clientRoot')
  const settingsRouteData = useRouteLoaderData('settingsRoot')

  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    setCurrentIndex(getIndex(location.pathname))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const handleChange = (event, newIndex) => {
    navigateTo(newIndex)
  }

  const handleSelectChange = (event) => {
    const newIndex = event.target.value
    navigateTo(newIndex)
  }

  const needsConfig = () => {
    return settingsRouteData?.settings === null
  }

  const navigateTo = (index) => {
    switch (index) {
      case 1:
        navigate('chargingpoints', { relative: 'path' })
        break
      case 2:
        navigate('pvSettings', { relative: 'path' })
        break
      default:
        navigate('management', { relative: 'path' })
    }
  }

  const getIndex = (path) => {
    if (path.includes('/chargingpoints')) {
      return 1
    } else if (path.includes('/pvSettings')) {
      return 2
    } else {
      return 0
    }
  }

  return (
    <div>
      <div>
        {smallScreen ?
          <FormControl sx={{
            width: '100%',
            paddingBottom: '80px',
          }}
          >
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={currentIndex}
              onChange={handleSelectChange}
            >
              <MenuItem value={0}>Lademanagements</MenuItem>
              <MenuItem value={1} disabled={needsConfig()}>Ladepunkte</MenuItem>
              {(customer?.hasPv && customer.hasPv === 1) &&
                (
                  <MenuItem value={2}>Photovoltaik disabled={needsConfig()}</MenuItem>
                )}
            </Select>
          </FormControl>
          :
          <CustomTabs
            value={currentIndex}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            aria-label="configuration-tabs"
          >
            <CustomTab label="Lademanagement" {...tabIdProps(0)} value={0}/>
            <CustomTab label="Ladepunkte" {...tabIdProps(1)} disabled={needsConfig()} value={1}/>
            {customer?.hasPv &&
              (
                <CustomTab label={'Photovoltaik'} {...tabIdProps(2)} disabled={needsConfig()} value={2}/>
              )}
          </CustomTabs>
        }
      </div>
      <TabPanel value={currentIndex} index={currentIndex}>
        <Outlet/>
      </TabPanel>
    </div>
  )
}

export default ConfigurationTabLayout