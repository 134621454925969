import React from 'react'
import Typography from '@mui/material/Typography'
import ChargingPointIcon from '../../pages/Dashboard/components/ChargingPointIcon'
import Grid from '@mui/material/Grid'
import { Checkbox } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt'
import Card from '@mui/material/Card'
import { styled } from '@mui/material/styles'

const PhaseText = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
  fontSize: '16px',
  fontWeight: '500',
}))

const PhaseValue = styled(Typography)({
  fontSize: '16px',
})

function getMetric (lmsVersion, phase, powerMeasurementPhase1, powerMeasurementPhase2, powerMeasurementPhase3) {
  if (lmsVersion !== 2) {
    let metric = {}
    if (phase) {
      metric.l1 = phase === 1 && electricIcon()
      metric.l2 = phase === 2 && electricIcon()
      metric.l3 = phase === 3 && electricIcon()
    } else {
      metric.l1 = electricIcon()
      metric.l2 = electricIcon()
      metric.l3 = electricIcon()
    }
    return metric
  } else {
    const p1 = powerMeasurementPhase1?.value || 0
    const p2 = powerMeasurementPhase2?.value || 0
    const p3 = powerMeasurementPhase3?.value || 0
    return { l1: toKiloWatt(p1), l2: toKiloWatt(p2), l3: toKiloWatt(p3) }
  }
}

function electricIcon () {
  return (
    <ElectricBoltIcon fontSize={'small'}/>
  )
}

function toKiloWatt (value) {
  return (value / 1000).toFixed(1)
    .toString()
    .replace('.', ',')
}

const ChargingPointInfoBox = ({ lmsVersion, onEdit, onActivate, chargingPoint }) => {
  const defaultCurrentStatus = {
    powerMeasurementPhase1: 0,
    powerMeasurementPhase2: 0,
    powerMeasurementPhase3: 0,
  }
  const currentStatus = chargingPoint.currentstatus || defaultCurrentStatus
  const metric = getMetric(lmsVersion, chargingPoint.phase, currentStatus.powerMeasurementPhase1, currentStatus.powerMeasurementPhase2, currentStatus.powerMeasurementPhase3)

  return (
    <Card
      elevation={2}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        borderRadius: 1,
        padding: 0.5,
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', paddingRight: 6 }}>
        <ChargingPointIcon status={currentStatus?.status} activated={chargingPoint.activated} width={65}/>
      </div>
      <Grid container direction={'row'} alignItems={'center'}>
        <Grid
          item
          xs={12}
          sm={5}
          sx={(theme) => ({
            paddingBottom: theme.spacing(0.5),
            [theme.breakpoints.up('md')]: {
              paddingBottom: theme.spacing(0),
            },
          })}
        >
          <Typography component="div" variant={'h5'}>
            {chargingPoint.name}
          </Typography>
          {lmsVersion === '2' &&
            <Typography component="div">
              {(currentStatus?.energyTotal?.value / 1000).toFixed(1)} kWh
            </Typography>
          }
        </Grid>
        <Grid item container xs={12} sm={6} justifyContent={{ xs: 'left', sm: 'right' }}>
          <div>
            <PhaseText paddingRight={4}>L1</PhaseText>
            <PhaseValue>{metric.l1}</PhaseValue>
          </div>
          <div>
            <PhaseText paddingRight={4}>L2</PhaseText>
            <PhaseValue>{metric.l2}</PhaseValue>
          </div>
          <div>
            <PhaseText>L3</PhaseText>
            <PhaseValue>{metric.l3}</PhaseValue>
          </div>
        </Grid>
      </Grid>
      <Grid item container xs={3} justifyContent={'center'} alignItems={'center'}>
        <Checkbox
          id={`activate-chargingpoint-${chargingPoint.id}`}
          disabled={chargingPoint.isDisconnected}
          color="primary"
          checked={chargingPoint.activated}
          onClick={() => onActivate()}
        />
        <IconButton
          sx={{ padding: 0 }}
          onClick={() => onEdit()}
          size="large">
          <EditIcon/>
        </IconButton>
      </Grid>
    </Card>
  )
}

export default ChargingPointInfoBox
