import { FormControl, MenuItem, Select } from '@mui/material'
import React, { useEffect, useState } from 'react'
import TabPanel, { tabIdProps } from '../../components/TabPanel/TabPanel'
import { Outlet, useLocation, useNavigate, useRouteLoaderData } from 'react-router'
import useMediaQuery from '@mui/material/useMediaQuery'
import { styled, useTheme } from '@mui/material/styles'
import { CustomTab, CustomTabs } from '../../components/TabMenu/TabMenu'

const RootDiv = styled('div')(({ theme }) => ({
  flexGrow: 1,
  width: '100%',
  backgroundColor: theme.palette.background.paper,
}))

const ChargingPointAuthorizationTabContainer = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const theme = useTheme()
  const { lmsVersion } = useRouteLoaderData('clientRoot')
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const FIRST_PAGE = 'Ladepunkte'
  const SECOND_PAGE = 'Ladekarten'
  const THIRD_PAGE = 'Nutzer*innen'

  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    setCurrentIndex(getIndex(location.pathname))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const handleChange = (event, newIndex) => {
    navigateTo(newIndex)
  }

  const handleSelectChange = (newIndex) => {
    navigateTo(newIndex)
  }

  const navigateTo = (index) => {
    switch (index) {
      case 1:
        navigate('tags', { relative: 'path' })
        break
      case 2:
        navigate('users', { relative: 'path' })
        break
      default:
        navigate('chargingpoints', { relative: 'path' })
    }
  }

  const getIndex = (path) => {
    if (path.includes('/tags')) {
      return 1
    } else if (path.includes('/users')) {
      return 2
    } else {
      return 0
    }
  }

  return (
    <RootDiv>
      {smallScreen ?
        <FormControl sx={{ width: '100%' }}>
          <Select
            labelId="user-tab-label"
            id="user-tab-select"
            value={currentIndex}
            onChange={handleChange}
          >
            <MenuItem value={0}>{FIRST_PAGE}</MenuItem>
            {lmsVersion === 2 &&
              (
                <MenuItem value={1}>{SECOND_PAGE}</MenuItem>
              )}
            {lmsVersion >= 1 &&
              (
                <MenuItem value={2}>{THIRD_PAGE}</MenuItem>
              )}
          </Select>
        </FormControl>
        :
        <CustomTabs
          value={currentIndex}
          onChange={(event, idx) => handleSelectChange(idx)}
          indicatorColor="primary"
          textColor="primary"
          aria-label="release-tabs"
        >
          <CustomTab label={FIRST_PAGE} {...tabIdProps(0)} value={0}/>
          {lmsVersion === 2 &&
            (
              <CustomTab label={SECOND_PAGE} {...tabIdProps(1)} value={1}/>
            )}
          {lmsVersion >= 1 &&
            (
              <CustomTab label={THIRD_PAGE} {...tabIdProps(2)} value={2}/>
            )}
        </CustomTabs>
      }
      <TabPanel value={currentIndex} index={currentIndex}>
        <Outlet/>
      </TabPanel>
    </RootDiv>
  )
}

export default ChargingPointAuthorizationTabContainer