import { IconButton, List, ListItem, ListItemButton, Popover, Tooltip, Typography } from '@mui/material'
import React from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ListItemText from '@mui/material/ListItemText'
import Divider from '@mui/material/Divider'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import LockIcon from '@mui/icons-material/Lock'
import { canUseApp, canUseCard } from '../../../utils'

const UnlockingListItem = ({ point, index, onPointAuthModeChange, handleTagClick, handleUserClick, version, canEdit, isDisconnected }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const USER = 'Nutzer*innen'

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <React.Fragment key={index}>
      <ListItem key={index}>
        <ListItemText key={index}
                      primary={
                        <Typography
                          sx={[
                            {
                              display: 'flex',
                              justifyContent: 'space-between',
                            },
                            isDisconnected && { color: 'text.disabled' },
                            version === 2 && { marginBottom: '-8px' },
                          ]}
                        >
                          {point.name}
                          <div>
                            {point.interfaceType === 'ocpp' ?
                              <Tooltip title={'Automatische Freigabe nicht möglich'}>
                              <span>
                                <IconButton disabled={true} size={'small'} id={`lockButton${point.id}`}>
                                  <LockIcon/>
                                </IconButton>
                              </span>
                              </Tooltip>
                              :
                              point.currentPolicy?.authMode !== 'DEFAULT_UNAUTHORIZED' ?
                                <IconButton disabled={!canEdit} onClick={() => onPointAuthModeChange(point)} size={'small'}>
                                  <LockOpenIcon fontSize={'small'}/>
                                </IconButton>
                                :
                                <IconButton disabled={!canEdit} onClick={() => onPointAuthModeChange(point)} size={'small'}>
                                  <LockIcon fontSize={'small'}/>
                                </IconButton>

                            }
                            {version >= 1 &&
                              <IconButton size={'small'} onClick={handleClick}>
                                <MoreVertIcon/>
                              </IconButton>
                            }
                          </div>
                        </Typography>
                      }
        />
      </ListItem>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List>
          <ListItemButton disabled={!canUseCard(point, version)} onClick={() => handleTagClick(point)}>
            Ladekarten
          </ListItemButton>
          <ListItemButton disabled={!canUseApp(point, version)} onClick={() => handleUserClick(point)}>
            {USER}
          </ListItemButton>
        </List>
      </Popover>
      <Divider/>
    </React.Fragment>
  )
}

const UnlockingList = ({ chargingPoints, onPointAuthModeChange, handleTagClick, handleUserClick, version, canEdit }) => {

  return (
    <div>
      {chargingPoints && chargingPoints.length > 0 && chargingPoints.map((point, index) => {
        let isDisconnected = point.controllerConnectionStatus === 'DISCONNECTED'
        return (
          <UnlockingListItem point={point} key={index} onPointAuthModeChange={onPointAuthModeChange} version={version} canEdit={canEdit} isDisconnected={isDisconnected} handleTagClick={handleTagClick}
                             handleUserClick={handleUserClick}/>
        )
      })}
    </div>
  )
}

export default UnlockingList