import React from 'react'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import { IconButton, List, ListItemButton, Tooltip } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { styled } from '@mui/material/styles'

const ButtonRoot = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
})

const TagsList = ({ entry, handleEdit, handleDelete, allowEdit, CustomDeleteIcon }) => {
  return (
    <List>
      {entry && entry.length > 0 && entry.map((entry, index) => (
        <React.Fragment key={index}>
          <ListItemButton key={index}
                          onClick={() => handleEdit(entry)}
          >
            <ListItemText key={index}
                          primary={
                            <Typography color={'inherit'}>
                              {entry.name}
                            </Typography>
                          }
                          secondary={
                            <Typography color="inherit" display={'inline'}
                                        style={{ whiteSpace: 'pre-line' }}>
                              ID: {entry.value}{'\n'}
                              Kostenstelle: {entry.accountId}{'\n'}
                              Kommentar: {entry.description || ''}
                            </Typography>
                          }/>
          </ListItemButton>
          {allowEdit && (
            <ButtonRoot>
              <IconButton
                size={'small'}
                sx={{ marginTop: '-170px' }}
                onClick={() => handleDelete(entry)}
              >
                {CustomDeleteIcon ? <CustomDeleteIcon/> :
                  <Tooltip title={'Karte löschen'}>
                    <DeleteIcon/>
                  </Tooltip>
                }
              </IconButton>
            </ButtonRoot>
          )}
          <Divider/>
        </React.Fragment>
      ))}
    </List>
  )
}

export default TagsList