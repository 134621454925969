import React, { useEffect } from 'react'
import { createBrowserRouter, createRoutesFromElements, Navigate, redirect, Route } from 'react-router-dom'
import { MainLayout, MinimalLayout } from '../layouts'

import {
  BaseDataPage,
  ConfigurationEditForm,
  DashboardPage,
  DocumentsPage,
  EnergyManagementPage,
  EvaluationPage,
  HelpPage,
  ImprintPage,
  InstallationPage,
  LicensesPage,
  LmsListPage,
  LogMessagesPage,
  MessagesPage,
  NotFoundPage,
  ProcessPage,
  ResetPasswordCompletedPage,
  ResetPasswordConfirmPage,
  ResetPasswordPage,
  SignInPage,
  UserManagementPage,
} from '../pages'

import { useSelector } from 'react-redux'
import { isChallengeDone, isNewPasswordRequired, loadCurrentAuthenticatedUser } from '../store'
import { Outlet, RouterProvider } from 'react-router'
import NewDocumentPage from '../pages/Documents/NewDocumentPage'
import NewLmsPage from '../pages/ChargingStationsOverview/NewLmsPage'
import EditLmsPage from '../pages/ChargingStationsOverview/EditLmsPage'
import ConfigurationTabContainer from '../pages/Configuration/Chargemanagement/ConfigurationTabContainer'
import ChargingpointsTabContainer from '../pages/Configuration/Chargepoints/ChargingpointsTabContainer'
import ConfigurationTabPage from '../pages/Configuration/Chargemanagement/ConfigurationTabPage'
import PvOverchargingEditForm from '../pages/Configuration/PV/PvOverchargingEditForm'
import AddChargingPointPage from '../pages/Configuration/Chargepoints/AddChargingPointPage'
import EditChargingPointPage from '../pages/Configuration/Chargepoints/EditChargingPointPage'
import EditExtenderPage from '../pages/Configuration/Chargepoints/EditExtenderPage'
import NewProcessPage from '../pages/Process/NewProcessPage'
import EditProcessPage from '../pages/Process/EditProcessPage'
import NewBaseDataPage from '../pages/BaseData/NewBaseDataPage'
import EditBaseDataPage from '../pages/BaseData/EditBaseDataPage'
import EditDigitalOutputPage from '../pages/EnergyManagement/DigitalOutputs/EditDigitalOutputPage'
import DigitalOutputsContainer from '../pages/EnergyManagement/DigitalOutputs/DigitalOutputsContainer'
import EditChargingSuggestion from '../pages/EnergyManagement/ChargingSuggesstion/EditChargingSuggestion'
import DigitalOutputGraphContainer from '../pages/EnergyManagement/evaluation/digitaloutput/DigitalOutputGraphContainer'
import PowerHistoryChartContainer from '../pages/Evaluation/PowerHistoryChartContainer'
import AnnualConsumptionChartContainer from '../pages/Evaluation/AnnualConsumptionChartContainer'
import ChargingProcessesContainer from '../pages/Evaluation/ChargingProcessesContainer'
import AddUserPage from '../pages/UserManagement/AddUserPage'
import EditUserPage from '../pages/UserManagement/EditUserPage'
import DataExportPage from '../pages/DataExport/DataExportPage'
import UserAuthorizationPage from '../pages/UserAuthorization/UserAuthorizationPage'
import AddUserAuthorizationPage from '../pages/UserAuthorization/AddUserAuthorizationPage'
import EditUserAuthorizationPage from '../pages/UserAuthorization/EditUserAuthorizationPage'
import UserSettingsPage from '../pages/Menu/UserSettings/UserSettingsPage'
import { customerLoader, settingsLoader, systemStatusLoader } from './routeLoader'
import { CPO_ROLES, SERVICE_ROLES, SUPPORT_ROLE } from '../security/roles'
import ErrorPage from '../pages/ErrorScreen/ErrorPage'
import UnlockingTabContainer from '../pages/Authorization/Chargingpoints/UnlockingTabContainer'
import TagsTabContainer from '../pages/Authorization/Tags/TagsTabContainer'
import NewUserPage from '../pages/Authorization/Users/NewUserPage'
import EditCPUserPage from '../pages/Authorization/Users/EditUserPage'
import NewTagPage from '../pages/Authorization/Tags/NewTagPage'
import EditTagPage from '../pages/Authorization/Tags/EditTagPage'
import ChargingPointTagsPage from '../pages/Authorization/Chargingpoints/ChargingPointTagsPage'
import ChargingPointUsersPage from '../pages/Authorization/Chargingpoints/ChargingPointUsersPage'
import UsersTabPage from '../pages/Authorization/Users/UsersTabPage'
import ChargingPointAuthorizationPage from '../pages/Authorization/ChargingPointAuthorizationPage'
import AdministrationPage from '../pages/Administration/AdministrationPage'
import NewFirmwarePage from '../pages/Administration/Firmware/NewFirmwarePage'
import EditFirmwarePage from '../pages/Administration/Firmware/EditFirmwarePage'
import FirmwareTab from '../pages/Administration/Firmware/FirmwareTab'
import InstallFirmwarePage from '../pages/Administration/Firmware/InstallFirmwarePage'
import StatusInstallFirmwarePage from '../pages/Administration/Firmware/StatusInstallFirmwarePage'
import NewPassword from '../pages/NewPassword/NewPassword'
import UserActionLayout from '../layouts/Main/UserActionLayout'

const Routes = () => {
  const challenge = useSelector(state => state.auth.challenge)
  const signedIn = useSelector(state => state.auth.signedIn)
  const authenticating = useSelector(state => state.auth.authenticating)
  const newPasswordRequired = isNewPasswordRequired(challenge)

  useEffect(() => {
    loadCurrentAuthenticatedUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [challenge])

  if (authenticating) {
    return null
  }

  const userSignedIn = () => {
    if (signedIn) {
      return redirect('/')
    }
    return null
  }

  const checkChallengeDone = () => {
    if (isChallengeDone(challenge)) {
      return redirect('/sign-in')
    }
    return null
  }

  const checkNewPasswordRequired = () => {
    if (newPasswordRequired) {
      return redirect('/new-password')
    }
    return null
  }

  const redirectIfNoUser = () => {
    if (!signedIn) {
      return redirect('/sign-in')
    }
    return systemStatusLoader()
  }

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" errorElement={<ErrorPage/>} element={signedIn ? <MainLayout/> : <MinimalLayout/>}>
        <Route path="imprint" element={<ImprintPage/>}/>
        <Route path="licenses" element={<LicensesPage/>}/>
        <Route loader={userSignedIn} element={<UserActionLayout/>}>
          <Route path="new-password" loader={checkChallengeDone} element={<NewPassword/>}/>
          <Route path="reset-password" element={<ResetPasswordPage/>}/>
          <Route path="set-password" element={<ResetPasswordConfirmPage/>}/>
          <Route path="reset-password-completed" element={<ResetPasswordCompletedPage/>}/>
          <Route path="sign-in" loader={checkNewPasswordRequired} element={<SignInPage/>}/>
        </Route>
        <Route id="root" loader={redirectIfNoUser} element={<Outlet/>}>
          <Route index element={<Navigate to="/stations"/>}/>
          <Route path="/stations">
            <Route index element={<LmsListPage/>}/>
            <Route path="new" element={<NewLmsPage roles={CPO_ROLES}/>}/>
            <Route path=":clientId/edit" element={<EditLmsPage roles={CPO_ROLES}/>}/>
          </Route>
          <Route path="/client/:clientId" id="clientRoot" loader={customerLoader}>
            <Route path="dashboard" element={<DashboardPage/>}/>
            <Route path="messages" element={<MessagesPage/>}/>
            <Route path="settings"
                   id="settingsRoot"
                   loader={settingsLoader}
                   shouldRevalidate={({ currentUrl }) => {return currentUrl.pathname.includes('/chargingpoints/new')}}
            >
              <Route element={<ConfigurationTabPage/>}>
                <Route index element={<Navigate to="management"/>}/>
                <Route path="management" element={<ConfigurationTabContainer/>}/>
                <Route path="chargingpoints" element={<ChargingpointsTabContainer/>}/>
                <Route path="pvSettings" element={<PvOverchargingEditForm/>}/>
              </Route>
              <Route path="chargingpoints">
                <Route path="new" element={<AddChargingPointPage/>}/>
                <Route path="edit/:pointId" element={<EditChargingPointPage/>}/>
                <Route path="edit/:pointId/extender/:pointIdExtender" element={<EditExtenderPage/>}/>
              </Route>
            </Route>
            <Route path="authorization">
              <Route element={<ChargingPointAuthorizationPage/>}>
                <Route index element={<Navigate to="chargingpoints"/>}/>
                <Route path="chargingpoints" element={<UnlockingTabContainer/>}/>
                <Route path="tags" element={<TagsTabContainer/>}/>
                <Route path="users" element={<UsersTabPage/>}/>
              </Route>
              <Route path="chargingpoints">
                <Route path=":pointId/tags" element={<ChargingPointTagsPage/>}/>
                <Route path=":pointId/userList" element={<ChargingPointUsersPage/>}/>
              </Route>
              <Route path="users">
                <Route path="new" element={<NewUserPage/>}/>
                <Route path=":userId/edit" element={<EditCPUserPage/>}/>
              </Route>
              <Route path="tags">
                <Route path="new" element={<NewTagPage/>}/>
                <Route path=":tagId/edit" element={<EditTagPage/>}/>
              </Route>
            </Route>
            <Route path="processes/">
              <Route index element={<ProcessPage roles={SERVICE_ROLES}/>}/>
              <Route path="new" element={<NewProcessPage roles={SERVICE_ROLES}/>}/>
              <Route path="edit/:processId" element={<EditProcessPage roles={SERVICE_ROLES}/>}/>
            </Route>
            <Route path="baseData">
              <Route index element={<BaseDataPage/>}/>
              <Route path="new" element={<NewBaseDataPage roles={SERVICE_ROLES}/>}/>
              <Route path="edit/:contactId" element={<EditBaseDataPage roles={SERVICE_ROLES}/>}/>
            </Route>
            <Route path="energy">
              <Route element={<EnergyManagementPage roles={SERVICE_ROLES}/>}>
                <Route index element={<Navigate to="outputs"/>}/>
                <Route path="outputs" element={<DigitalOutputsContainer roles={SERVICE_ROLES}/>}/>
                <Route path="optimalCharging" element={<EditChargingSuggestion roles={SERVICE_ROLES}/>}/>
                <Route path="outputsGraph" element={<DigitalOutputGraphContainer roles={SERVICE_ROLES}/>}/>
              </Route>
              <Route path="outputs/edit" element={<EditDigitalOutputPage roles={SERVICE_ROLES}/>}/>
            </Route>
            <Route path="documents">
              <Route index element={<DocumentsPage roles={SERVICE_ROLES}/>}/>
              <Route path="new" element={<NewDocumentPage roles={SERVICE_ROLES}/>}/>
            </Route>
            <Route path="evaluation">
              <Route element={<EvaluationPage/>}>
                <Route index element={<Navigate to="history"/>}/>
                <Route path="history" element={<PowerHistoryChartContainer/>}/>
                <Route path="consumption" element={<AnnualConsumptionChartContainer/>}/>
                <Route path="sessions" element={<ChargingProcessesContainer/>}/>
              </Route>
            </Route>
            <Route path="users">
              <Route index element={<UserManagementPage roles={SUPPORT_ROLE}/>}/>
              <Route path="new" element={<AddUserPage roles={SUPPORT_ROLE}/>}/>
              <Route path="edit" element={<EditUserPage roles={SUPPORT_ROLE}/>}/>
            </Route>
            <Route path="export" element={<DataExportPage roles={CPO_ROLES}/>}/>
            <Route path="user-management">
              <Route index element={<UserAuthorizationPage roles={CPO_ROLES}/>}/>
              <Route path="new" element={<AddUserAuthorizationPage roles={CPO_ROLES}/>}/>
              <Route path="edit" element={<EditUserAuthorizationPage roles={CPO_ROLES}/>}/>
            </Route>
            <Route path="logmessages" element={<LogMessagesPage roles={SUPPORT_ROLE}/>}/>
            <Route path="user/settings" element={<UserSettingsPage/>}/>
            <Route path="installation" element={<InstallationPage roles={SERVICE_ROLES}/>}/>
            <Route path="initConfiguration" element={<ConfigurationEditForm/>}/>
          </Route>
          <Route path="administration">
            <Route element={<AdministrationPage roles={SERVICE_ROLES}/>}>
              <Route index element={<Navigate to="firmware"/>}/>
              <Route path="firmware" element={<FirmwareTab roles={SERVICE_ROLES}/>}/>
            </Route>
            <Route path="firmware">
              <Route path="new" element={<NewFirmwarePage roles={SERVICE_ROLES}/>}/>
              <Route path=":manufacturer/:version/edit" element={<EditFirmwarePage roles={SERVICE_ROLES}/>}/>
              <Route path=":manufacturer/:version/install" element={<InstallFirmwarePage roles={SERVICE_ROLES}/>}/>
              <Route path=":manufacturer/:version/install/status" element={<StatusInstallFirmwarePage roles={SERVICE_ROLES}/>}/>
            </Route>
          </Route>
          <Route path="users">
            <Route index element={<UserManagementPage roles={SUPPORT_ROLE}/>}/>
            <Route path="new" element={<AddUserPage roles={SUPPORT_ROLE}/>}/>
            <Route path="edit" element={<EditUserPage roles={SUPPORT_ROLE}/>}/>
          </Route>
          <Route path="export" element={<DataExportPage roles={CPO_ROLES}/>}/>
          <Route path="user/settings" element={<UserSettingsPage/>}/>
          <Route path="help" element={<HelpPage/>}/>
          <Route path={'/*'} element={<Navigate to="/not-found"/>}/>
        </Route>
        <Route path={'/not-found'} element={<NotFoundPage/>}/>
        <Route path={'/*'} element={<Navigate to="/sign-in/"/>}/>
      </Route>,
    ))

  return (
    <React.Fragment>
      <RouterProvider router={router}/>
    </React.Fragment>)

}

export default Routes
