import { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import { useFormik } from 'formik'
import { firmwareValidationSchema } from './FirmwareValidation'
import CustomFormikTextField from '../../../components/crud/widgets/components/CustomFormikTextField'
import { SubmitFab } from '../../../components'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { useSnackbar } from 'notistack'
import CustomFormikSelectField from '../../../components/crud/widgets/components/CustomFormikSelectField'
import CustomDropzone from '../../../components/Dropzone/CustomDropzone'

const FirmwareWidget = ({ save, firmware, maxFileSize }) => {
  const { enqueueSnackbar } = useSnackbar()
  const manufacturers = ['ABL', 'Alfen', 'Flotteladen']
  const [submitEnabled, setSubmitEnabled] = useState(false)

  const formik = useFormik({
    initialValues: {
      manufacturer: manufacturers[0],
      version: '',
      model: '',
      comment: '',
      filePath: null,
      file: null,
      fileName: '',
    },
    validationSchema: firmwareValidationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    onSubmit: (values, actions) => {
      save(values, actions)
    },
  })

  useEffect(() => {
    if (firmware && Object.values(firmware).length > 0) {
      formik.setValues(firmware)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firmware])

  const handleFileChange = (files) => {
    if (formik.values.filePath) {
      // edit mode
      setSubmitEnabled(true)
      return
    }

    if (files.length > 0) {
      formik.values.file = files[0]
      formik.values.fileName = files[0].name
      setSubmitEnabled(true)
    } else if (files.length === 0) {
      formik.values.file = undefined
      formik.values.fileName = undefined
      setSubmitEnabled(false)
    }
  }

  const handleReject = (error) => {
    enqueueSnackbar(error.message, { variant: 'error' })
  }

  function getFileName () {
    return formik.values.filePath ? formik.values.filePath.split('/')
      .pop() : ''
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid
        container
        alignItems={'center'}
        spacing={3}>
        <Grid
          item
          xs={12}>
          <CustomFormikSelectField
            value={formik.values.manufacturer}
            values={manufacturers}
            onChange={formik.handleChange}
            id={'manufacturer'}
            name={'manufacturer'}
            label={'Hersteller'}
            error={Boolean(formik.errors?.manufacturer)}
            helperText={formik.errors?.manufacturer || ''}
            disabled={Boolean(formik.values.filePath)}
          />
        </Grid>
        <Grid
          item
          xs={12}>
          <CustomFormikTextField
            value={formik.values.version}
            onChange={formik.handleChange}
            id={'version'}
            name={'version'}
            label={'Version'}
            error={Boolean(formik.errors?.version)}
            helperText={formik.errors?.version || ''}
            disabled={Boolean(formik.values.filePath)}
          />
        </Grid>
        <Grid
          item
          xs={12}>
          <CustomFormikTextField
            value={formik.values.model}
            onChange={formik.handleChange}
            id={'model'}
            name={'model'}
            label={'Modell'}
            error={Boolean(formik.errors?.model)}
            helperText={formik.errors?.model || ''}
          />
        </Grid>
        <Grid
          item
          xs={12}>
          <Box paddingBottom={0.5}>
            <Typography>Kommentar</Typography>
          </Box>
          <CustomFormikTextField
            value={formik.values.comment || ''}
            onChange={formik.handleChange}
            multiline
            rows={5}
            variant={'outlined'}
            id={'comment'}
            name={'comment'}
            error={Boolean(formik.errors?.comment)}
            helperText={formik.errors?.comment || ''}
          />
        </Grid>
        <Grid
          item
          xs={12}
          hidden={!formik.values.filePath}>
          <CustomFormikTextField
            value={getFileName()}
            disabled
            id={'filePath'}
            name={'filePath'}
            label={'Datei'}
            error={Boolean(formik.errors?.filePath)}
            helperText={formik.errors?.filePath || ''}
          />
        </Grid>
        <Grid
          item
          xs={12}
          hidden={formik.values.filePath}>
          <CustomDropzone
            dropzoneText={'Datei hierher ziehen und ablegen oder hier klicken'}
            maxFileSize={maxFileSize}
            onChange={(files) => handleFileChange(files)}
            onDropRejected={(e) => handleReject(e)}
          />
        </Grid>
      </Grid>
      <SubmitFab disabled={!submitEnabled} show={true}/>
    </form>
  )
}

export default FirmwareWidget