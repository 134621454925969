import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { styled, useTheme } from '@mui/material/styles'
import { IconButton } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import QuickHelpButton from '../QuickHelpButton/QuickHelpButton'
import { getRoles } from '../../security/roles'
import { useSelector } from 'react-redux'
import AuthGuard from '../AuthGuard/AuthGuard'
import { Link } from 'react-router-dom'
import Button from '@mui/material/Button'
import SettingsPowerIcon from '@mui/icons-material/SettingsPower'
import { useParams } from 'react-router'
import useMediaQuery from '@mui/material/useMediaQuery'
import { isNewPasswordRequired } from '../../store'

const RootDiv = styled('div')({
  flexGrow: 1,
  height: '100%',
  zIndex: 0,
})

const Page = props => {

  const { children, roles, viewName, innerClassName, onBack, helpText, helpTitle, installation, newPassword } = props
  const { clientId } = useParams()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const challenge = useSelector(state => state.auth.challenge)
  const userRoles = useSelector(state => getRoles(state))
  const newPasswordRequired = isNewPasswordRequired(challenge)

  return (
    <AuthGuard requiredRole={roles} userRoles={userRoles} newPasswordRequired={newPassword ? false : newPasswordRequired}>
      <RootDiv>
        <Grid
          container
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          spacing={2}
          paddingTop={3}
          height={'100%'}
          sx={(theme) => ({
            [theme.breakpoints.down('sm')]: {
              paddingLeft: theme.spacing(0),
              paddingRight: theme.spacing(0),
            },
          })}
        >
          <Grid item xs={10} sm={7}>
            <Typography variant={'h1'} style={{ fontWeight: '600', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', marginLeft: -3 }}>
              {viewName}
            </Typography>
          </Grid>
          {installation && (
            <Grid
              item
              xs={2} sm={2}
              container
              direction={'row'}
              justifyContent={'flex-end'}
            >
              {smallScreen ?
                <Grid
                  item
                  xs={2}
                >
                  <Button
                    color={'primary'}
                    variant={'contained'}
                    component={Link}
                    to={`/client/${clientId}/installation`}
                  >
                    <SettingsPowerIcon/>
                  </Button>
                </Grid>
                :
                <Grid
                  item
                  xs={12}>
                  <Button
                    color={'primary'}
                    variant={'contained'}
                    fullWidth
                    component={Link}
                    style={{ width: '150px' }}
                    to={`/client/${clientId}/installation`}
                  >
                    INBETRIEBNAHME
                  </Button>
                </Grid>
              }
            </Grid>
          )}
          {helpText && (
            <Grid
              item
              xs={1}
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'flex-end'}
              marginRight={'30px'}
            >
              <QuickHelpButton helpText={helpText} helpTitle={helpTitle}/>
            </Grid>
          )}
          <Grid
            item
            container xs={12}
            alignItems={'flex-end'}
            marginLeft={'-4px'}
          >
            {onBack && <IconButton onClick={() => onBack()} size={'small'}>
              <ArrowBackIosIcon sx={{ color: 'primary.alternateContrastText' }}/>
              <Typography sx={{ color: 'primary.alternateContrastText' }}>
                Zurück
              </Typography>
            </IconButton>}
          </Grid>
          <Grid item xs={12} className={innerClassName}>
            {children}
          </Grid>
        </Grid>
      </RootDiv>
    </AuthGuard>
  )
}

Page.propTypes = {
  viewName: PropTypes.string,
  pageTitle: PropTypes.string,
  helpText: PropTypes.string,
  helpTitle: PropTypes.string,
  roles: PropTypes.array,
}

export default Page
