import React, { useState } from 'react'
import WbSunnyIcon from '@mui/icons-material/WbSunny'
import Checkbox from '@mui/material/Checkbox'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { IconButton, Typography } from '@mui/material'
import TypDeclaration from '../TypDeclaration/TypDeclaration'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { isServicePerson } from '../../security/roles'

const ChargingPointAccordionMobile = ({ item, userRoles, pvSettings, hasPv, onActivate, version, selectMore }) => {
  const [extended, setExtended] = useState(false)
  let isDisconnected = item.controllerConnectionStatus === 'DISCONNECTED'
  const hasExtenders = item.extenders && item.extenders.length > 0

  const getManufacturerName = (manufacturer) => {
    switch (manufacturer) {
      case 'alfen':
        return 'Alfen'
      case 'abl' :
        return 'ABL'
      case 'amperfied':
        return 'AMP'
      default:
        return 'FL'
    }
  }

  const rightSideBox = (item, parent) => {
    return (
      <Grid
        container
        alignItems={'center'}
        display="flex"
        justifyContent={'flex-end'}
        sx={[
          isDisconnected && { color: 'text.disabled' },
        ]}
        align={'end'}
        spacing={1}>
        {hasPv && pvSettings?.enabled &&
          (item.pvGuided ?
              <Grid item>
                <WbSunnyIcon style={{ marginBottom: '-8px', fill: 'rgba(239,227,49,0.8)' }}/>
              </Grid>
              :
              <Grid item>
                <WbSunnyIcon style={{ marginBottom: '-8px' }}/>
              </Grid>
          )}
        <Grid item>
          <Checkbox
            disabled={isDisconnected || !isServicePerson(userRoles)}
            checked={item.activated}
            onClick={() => {
              onActivate(item)
            }}
            style={{ padding: 0 }}
          />
        </Grid>
        {parent ?
          <Grid item xs={3.5}>
            <IconButton id={`more-chargingpoint-${item.id}`} disabled={!isServicePerson(userRoles)}
                        onClick={(event) => selectMore(item, event.currentTarget)}>
              <MoreVertIcon/>
            </IconButton>
          </Grid>
          :
          <Grid item style={{ marginRight: '40px' }}/>
        }
      </Grid>
    )
  }

  return (
    <React.Fragment>
      <ListItem
        sx={[
          isDisconnected && { color: 'text.disabled' },
        ]}
        secondaryAction={rightSideBox(item, true)}
      >
        <ListItemText primary={
          <div>
            <Typography color="inherit">{item.name}</Typography>
          </div>
        }
                      disableTypography
                      secondary={
                        <React.Fragment>
                          <Typography color="inherit" sx={{ fontWeight: 600 }}>
                            {version >= 2 ?
                              'Serien-Nr.:' + item.serialNumber
                              :
                              <TypDeclaration type={item.type}/>
                            }
                          </Typography>
                          {version >= 2 &&
                            <Typography color="inherit">
                              Hersteller: {getManufacturerName(item.manufacturer)}
                            </Typography>
                          }
                          <Typography color="inherit">
                            {version >= 2 ?
                              item.currentStatus?.info?.firmware === undefined ? 'Firmware:' : 'Firmware: ' + item.currentStatus?.info?.firmware
                              :
                              'Ladeleistungen in kW: ' + item.maxPower.toString()
                                .replace('.', ',') + ' kW'
                            }
                          </Typography>
                          <Typography color="inherit">
                            Zone: {item.areaName}
                          </Typography>
                        </React.Fragment>
                      }/>
      </ListItem>
      {
        hasExtenders &&
        <div style={{ marginTop: -44 }} align={'end'}>
          <IconButton onClick={() => setExtended(!extended)}>
            <KeyboardArrowDownIcon
              sx={[
                extended ? { transition: 'transform .5s', transform: 'rotate(180deg)' } : { transition: 'transform .5s' },
              ]}
            />
          </IconButton>
        </div>
      }
      {
        hasExtenders && extended &&
        (item.extenders.map((extender) => (
          <div>
            <Divider component="li" sx={{ borderBottomWidth: 1, marginLeft: 2, marginRight: '40px' }}/>
            <Grid sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingLeft: 2,
              paddingBottom: 2,
              paddingTop: 2,
            }}>
              <Grid
                item
              >
                <Typography sx={[
                  isDisconnected && { color: 'text.disabled' },
                ]}
                >
                  {extender.name}
                </Typography>
                <Typography
                  color="inherit"
                  sx={[
                    isDisconnected ? { color: 'text.disabled' } : { fontWeight: 600 },
                  ]}
                >
                  {!extender.serialNumber.endsWith('*2') &&
                    'Serien-Nr.: ' + extender.serialNumber
                  }
                </Typography>
              </Grid>
              <Grid
                item
              >
                {rightSideBox(item)}
              </Grid>
            </Grid>
          </div>
        )))}
      <Divider component="li" sx={{ borderBottomWidth: 1.5 }}/>
    </React.Fragment>
  )
}

export default ChargingPointAccordionMobile