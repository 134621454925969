import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingBackdrop, LoadMoreButton, Poller } from '../../components'
import MessagesList from './MessagesList'
import MessagesTable from './MessagesTable'
import LogMessageDialog from './LogMessageDialog'
import { FormControlLabel, Grid, Switch, Typography } from '@mui/material'
import { useParams } from 'react-router'
import { useSnackbar } from 'notistack'
import { useTheme } from '@mui/material/styles'
import { MobileDateTimePicker } from '@mui/x-date-pickers'
import useMediaQuery from '@mui/material/useMediaQuery'
import { fetchLastLogMessages, fetchMoreLogMessages } from '../../store'

const LogMessagesContainer = () => {
  const dispatch = useDispatch()
  const messages = useSelector(state => state.messages.logMessageList)
  const payload = useSelector(state => state.messages.logMessagePayload)

  const { clientId } = useParams()
  const { enqueueSnackbar } = useSnackbar()

  const [loading, setLoading] = useState(false)
  const [loadingMore, setLoadingMore] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [sortStartDate, setSortStartDate] = useState(new Date())
  const [sortEndDate, setSortEndDate] = useState(new Date())
  const [filter, setFilter] = useState(false)
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const SHOW_MORE_MESSAGES = 'Weitere Meldungen anzeigen'

  const handleStartDateChange = (value) => {
    if (isValidDate(value)) {
      if (value < sortEndDate) {
        setSortStartDate(value)
      } else {
        enqueueSnackbar('Startdatum darf nicht nach dem Enddatum liegen', {
          variant: 'warning',
        })
      }
    }
  }

  const handleEndDateChange = (value) => {
    if (isValidDate(value)) {
      if (value >= sortStartDate) {
        setSortEndDate(value)
      } else {
        enqueueSnackbar('Enddatum darf nicht vor dem Startdatum liegen', {
          variant: 'warning',
        })
      }
    }
  }

  const isValidDate = (d) => {
    return d instanceof Date && !isNaN(d)
  }

  const onOpen = (item) => {
    try {
      setDialogOpen(true)
      setSelectedItem(item)
    } catch (e) {
      throw new Error(e)
    }
  }

  const onClose = () => {
    try {
      setDialogOpen(false)
      setSelectedItem(null)
    } catch (e) {
      throw new Error(e)
    }
  }

  const onLoadMore = (cursor) => {
    try {
      if (filter) {
        dispatch(fetchMoreLogMessages({
          clientId: clientId,
          cursor: cursor,
          from: sortStartDate.toISOString(),
          to: sortEndDate.toISOString(),
        }))
      } else {
        dispatch(fetchMoreLogMessages({ clientId: clientId, cursor: cursor }))
      }
    } catch (e) {
      throw new Error(e)
    }
  }

  useEffect(() => {
    if (filter) {
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortStartDate, sortEndDate])

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId])

  const fetchData = () => {
    try {
      setLoading(true)
      if (clientId) {
        if (filter) {
          dispatch(fetchLastLogMessages({
            clientId: clientId,
            from: sortStartDate.toISOString(),
            to: sortEndDate.toISOString(),
          }))
        } else {
          dispatch(fetchLastLogMessages({ clientId: clientId }))
        }
      }
      setLoading(false)
    } catch (e) {
      throw new Error(e)
    }
  }

  return (
    <React.Fragment>
      <LoadingBackdrop open={loading}/>
      <Poller action={() => {
        if (loadingMore === false && filter === false) {
          fetchData()
        }
      }}/>
      <Grid container spacing={2} sx={{ marginBottom: 2 }} alignItems={'flex-end'}>
        <Grid item>
          <MobileDateTimePicker
            slotProps={{
              actionBar: { actions: ['today', 'cancel', 'accept'] },
              textField: { variant: 'standard' },
            }}
            value={sortStartDate}
            disableFuture
            onChange={handleStartDateChange}
            label="Start"
            maxDate={sortEndDate}
            maxDateMessage={'Datum darf nicht nach dem Enddatum sein'}
            invalidDateMessage={'Fehlerhaftes Datumsformat'}
            disabled={!filter}
          />
        </Grid>
        <Grid item>
          <MobileDateTimePicker
            slotProps={{
              actionBar: { actions: ['today', 'cancel', 'accept'] },
              textField: { variant: 'standard' },
            }}
            value={sortEndDate}
            disableFuture
            onChange={handleEndDateChange}
            label="Ende"
            minDate={sortStartDate}
            maxDateMessage={'Datum darf nicht nach dem Enddatum sein'}
            invalidDateMessage={'Fehlerhaftes Datumsformat'}
            disabled={!filter}
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                checked={filter}
                onChange={() => setFilter(!filter)}
                color={'primary'}
              />
            }
            label={'Filter aktivieren'}
          />
        </Grid>
      </Grid>
      {(messages.length === 0) ? (
        <div>
          <Typography variant={'h3'}
                      align={'center'}
                      sx={{
                        paddingTop: '50px',
                        paddingBottom: '10px',
                        fontWeight: '750',
                      }}
          >
            Es gibt keine aktuellen Meldungen
          </Typography>
        </div>
      ) : (
        <div>
          {smallScreen ?
            <MessagesList messages={messages} onOpen={onOpen}/>
            :
            <MessagesTable messages={messages} onOpen={onOpen}/>
          }
        </div>
      )}
      <LogMessageDialog dialogOpen={dialogOpen} onClose={onClose} item={selectedItem}/>
      {!payload?.last &&
        <div style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          marginTop: '18px',
        }}
        >
          <LoadMoreButton
            onClick={() => {
              setLoadingMore(true)
              onLoadMore(payload.pageable.cursor)
            }}
            label={SHOW_MORE_MESSAGES}
          />
        </div>
      }
    </React.Fragment>
  )
}

export default LogMessagesContainer