import { List, ListItemButton, Popover, Tooltip } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { RestartAlt } from '@mui/icons-material'
import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

const ChargingPointMoreSelect = ({ chargePoint, anchor, setAnchor, onRestart, onEdit }) => {
  const [openDialog, setOpenDialog] = useState(false)

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const openPopover = Boolean(anchor)

  const handleClose = () => {
    setAnchor(null)
    setOpenDialog(false)
  }

  const onConfirmRestartDialog = (cp) => {
    handleClose()
    onRestart(cp)
  }

  const isRestartDisabled = (chargePoint) => {
    return chargePoint.interfaceType !== 'ocpp' || chargePoint.controllerConnectionStatus === 'DISCONNECTED'
  }

  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={openDialog}
        onClose={() => handleClose()}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <DialogContentText>
            Soll der Ladepunkt neu gestartet werden?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => handleClose()} color="primary">
            {'Abbrechen'}
          </Button>
          <Button
            onClick={() => onConfirmRestartDialog(chargePoint)}
            color="primary"
            autoFocus>
            {'Bestätigen'}
          </Button>
        </DialogActions>
      </Dialog>
      <Popover
        open={openPopover}
        anchorEl={anchor}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List>
          <ListItemButton id={`popover-edit-${chargePoint.id}`} onClick={() => onEdit(chargePoint)}>
            <Tooltip title={'Bearbeiten'} PopperProps={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -80],
                  },
                },
              ],
            }}>
              <EditIcon/>
            </Tooltip>
          </ListItemButton>
          <ListItemButton id={`popover-restart-${chargePoint.id}`} disabled={isRestartDisabled(chargePoint)} onClick={() => setOpenDialog(true)}>
            <Tooltip title={'Neustart'}>
              <RestartAlt/>
            </Tooltip>
          </ListItemButton>
        </List>
      </Popover>
    </React.Fragment>
  )
}

export default ChargingPointMoreSelect