import React, { useEffect, useState } from 'react'
import { Autocomplete, Card, FormControlLabel, List, ListItem, Paper, TextField } from '@mui/material'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText'
import EditIcon from '@mui/icons-material/Edit'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

const tableSx = {
  cursor: 'default',
  height: '100px',
  marginTop: '1px',
  overflow: 'auto',
  '&.MuiList-root': {
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#eceef2',
      borderRadius: '180px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#9b9b9b',
      borderRadius: '180px',
    },
  },
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>
const checkedIcon = <CheckBoxIcon fontSize="small"/>

const CustomPaper = ({ children, selectAll, handleSelectAll, selectedChargingPoints, ...paperProps }) => (
  <Paper {...paperProps} style={{ border: '1px solid #B9B9B9', boxShadow: 'none' }}>
    <Box
      onMouseDown={(e) => e.preventDefault()} // prevent blur
      pl={1.5}
      py={0}
    >
      <FormControlLabel
        label="Alle auswählen"
        onClick={(e) => {
          e.preventDefault() // prevent blur
          handleSelectAll()
        }}
        control={
          <Checkbox
            id={`select-all-checkbox-for-autocomplete-1`}
            size="small"
            checked={selectAll}
          />
        }
      />
    </Box>
    <Divider/>
    {children}
  </Paper>)

const ChargingPointMultiSelector = ({ chargingPoints, selectedChargingPoints, setSelectedChargingPoints }) => {

  const [chargingPointMap, setChargingPointMap] = useState(new Map())
  const [selectAll, setSelectAll] = useState(false)
  const [options, setOptions] = React.useState([])

  const getLabel = (key) => {
    return chargingPointMap.get(key) || ''
  }

  const handleSelectAll = () => {
    setSelectAll((prev) => {
      if (!prev) setSelectedChargingPoints(options)
      else setSelectedChargingPoints([])
      return !prev
    })
  }

  useEffect(() => {
    if (chargingPoints && Object.values(chargingPoints).length > 0) {
      const cpMap = new Map(chargingPoints.map(obj => [obj.id, String(obj.name)]))
      setChargingPointMap(cpMap)
      const optionsArray = Array.from(cpMap.keys())
      optionsArray.sort()
      setOptions(optionsArray)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chargingPoints])

  return (
    <React.Fragment>
      <Card style={{ border: '1px solid #B9B9B9', boxShadow: 'none' }}>
        <Autocomplete
          multiple
          disableCloseOnSelect
          disablePortal={true}
          limitTags={5}
          sx={{
            '& .MuiAutocomplete-popupIndicator': { transform: 'none' },
          }}
          popupIcon={<EditIcon/>}
          defaultValue={selectedChargingPoints || []}
          value={selectedChargingPoints || []}
          options={options}
          getOptionLabel={(option) => getLabel(option)}
          onChange={(_e, value, reason) => {
            if (reason === 'clear' || reason === 'removeOption') {
              setSelectAll(false)
            }
            if (reason === 'selectOption' && value.length === options.length) {
              setSelectAll(true)
            }
            setSelectedChargingPoints(value)
          }}
          renderOption={(props, option, { selected }) => (
            <li {...props} key={props.key}  style={{ padding: '8px 8px 8px 10px' }}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ padding: 0, marginRight: 10 }}
                checked={selected}
              />
              {getLabel(option)}
            </li>
          )}
          renderInput={(params) => (
            <TextField {...params} variant={'standard'} placeholder=" Ladepunkte auswählen..."/>
          )}
          renderTags={() => null}
          ListboxProps={
            {
              style: {
                maxHeight: '200px',
              },
            }
          }
          PaperComponent={CustomPaper}
          slotProps={{ paper: { selectedChargingPoints, selectAll, handleSelectAll } }}
        />
        <List
          divider={<Divider inset="..."/>}
          sx={tableSx}
        >
          {selectedChargingPoints.length < 1 ?
            <ListItem key={`item-no-cp`} sx={{ padding: '5px 2px 2px 5px' }}>
              <ListItemText primary={'Keine Ladepunkte ausgewählt'}/>
            </ListItem>
            :
            selectedChargingPoints.map((value) => {
              const valueName = getLabel(value)
              return (valueName && <div>
                <ListItem key={`item-${value}`} sx={{ padding: '3px 0px 10px 5px', marginTop: -1 }}>
                  {valueName}
                </ListItem>
              </div>)
            })
          }
        </List>
      </Card>
    </React.Fragment>

  )
}

export default ChargingPointMultiSelector