import React, { useEffect, useState } from 'react'
import { backendApiClient } from '../../../apiClient'
import { useNavigate, useParams } from 'react-router'
import { useSnackbar } from 'notistack'
import { useLocation } from 'react-router-dom'
import TagWidget from './TagWidget'
import CardsEditHelpText from '../../../help-texts/FL_Authorization_Cards_Edit.md'
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from '../../Configuration/Chargepoints/lmsVersionTwo/ChargingPointVersionTwoConstants'
import Page from '../../../components/Page/Page'

const EditTagPage = () => {
  const { clientId, tagId } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const location = useLocation()

  const [chargingPoints, setChargingPoints] = useState([])
  const [tag, setTag] = useState(location.state?.tag || {})
  const [helpText, setHelpText] = useState()
  const [loading, setLoading] = useState(false)

  const ID_TOKEN_URL = `/api/client/${clientId}/id-tokens/${tagId}`
  const CP_URL = `/api/client/${clientId}/chargingpoints`
  const TITLE = 'Ladekarte bearbeiten'

  useEffect(() => {
    fetchChargingPoints()
    fetchIdToken()
    fetchHelpText()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchHelpText = () => {
    fetch(CardsEditHelpText)
      .then(res => res.text())
      .then(text => setHelpText(text))
      .catch(error => throw new Error(error))
  }

  const fetchIdToken = () => {
    if (tag && Object.values(tag).length > 0) {
      return
    }
    backendApiClient.get(ID_TOKEN_URL)
      .then((response) => {
        setTag(response)
      })
      .catch((e) => throw new Error(e))
  }

  const fetchChargingPoints = () => {
    setLoading(true)
    backendApiClient.get(CP_URL)
      .then((response) => {
        setChargingPoints(response)
      })
      .catch((e) => throw new Error(e))
      .finally(() => setLoading(false))
  }

  const save = (data, actions) => {
    return backendApiClient.put(ID_TOKEN_URL, data)
      .then(() => {
        navigate(-1)
        enqueueSnackbar(SNACKBAR_SUCCESS, {
          variant: 'success',
        })
      })
      .catch((error) => {
        if (error._response.statusCode === 409) {
          actions.setErrors({ value: 'Karten-ID bereits vergeben' })
        } else {
          enqueueSnackbar(SNACKBAR_ERROR, { variant: 'error' })
        }
      })
  }

  return (
    <Page viewName={TITLE} pageTitle={TITLE} onBack={() => navigate(-1)}
          helpText={helpText} helpTitle={TITLE}>
      <TagWidget chargingPoints={chargingPoints} save={save} tag={tag} loading={loading}/>
    </Page>
  )
}

export default EditTagPage
