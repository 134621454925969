import React from 'react'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import { IconButton, List, ListItemButton, Tooltip } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { styled } from '@mui/material/styles'

const ButtonRoot = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
})

const UsersList = ({ content, handleEdit, handleDelete, allowEdit, CustomDeleteIcon }) => {
  return (
    <List>
      {content && content.length > 0 && content.map((entry, index) => (
        <React.Fragment key={index}>
          <ListItemButton key={index}
                          onClick={() => {
                            if (allowEdit) {
                              handleEdit(entry)
                            }
                          }}
          >
            <ListItemText key={index}
                          primary={
                            <Typography color={'inherit'}>
                              {entry.name}
                            </Typography>
                          }
                          secondary={
                            <Typography color="inherit" display={'inline'}
                                        style={{ whiteSpace: 'pre-line' }}>
                              Kostenstelle: {entry.accountId}{'\n'}
                              Kommentar: {entry.comment && entry.comment.replace(/(.{30})..+/, '$1…')}
                            </Typography>
                          }/>
          </ListItemButton>
          {allowEdit && (
            <ButtonRoot>
              <IconButton sx={{ marginTop: '-130px' }} size={'small'} onClick={() => handleDelete(entry)}>
                {CustomDeleteIcon ? <CustomDeleteIcon/> :
                  <Tooltip title={'Nutzer*in löschen'}>
                    <DeleteIcon/>
                  </Tooltip>
                }
              </IconButton>
            </ButtonRoot>
          )}
          <Divider/>
        </React.Fragment>
      ))}
    </List>
  )
}

export default UsersList