import React from 'react'
import { getRoles } from '../../../../security/roles'
import List from '@mui/material/List'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { ChargingPointAccordionMobile } from '../../../../components'

const CpList = (props) => {
  const { chargingPoints, onEdit, pvSettings, hasPv, version, onActivate, selectMore } = props
  const userRoles = useSelector(state => getRoles(state))

  return (
    <List>
      {chargingPoints && chargingPoints.length > 0 && chargingPoints
        .filter(cp => isCpLMS01(cp) || isCpLMS02Main(cp))
        .map((item, index) => {
          return (
            <ChargingPointAccordionMobile item={item}
                                          key={index}
                                          index={index}
                                          pvSettings={pvSettings}
                                          hasPv={hasPv}
                                          userRoles={userRoles}
                                          onEdit={onEdit}
                                          onActivate={onActivate}
                                          version={version}
                                          selectMore={selectMore}/>
          )
        })}
    </List>
  )
}

function isCpLMS01 (chargingPoint) {
  return chargingPoint.interfaceType === 'na' || chargingPoint.interfaceType == null
}

function isCpLMS02Main (chargingPoint) {
  return chargingPoint.connectorId == null || chargingPoint.connectorId === 1
}

CpList.propTypes = {
  chargingPoints: PropTypes.array,
  onEdit: PropTypes.func,
  pvSettings: PropTypes.object,
  userRoles: PropTypes.array,
}

export default CpList